const baseUrl = "/zp-auth/v1/"
import http from './http';
class AuthApi {
  static login(param) {
    return http.post(baseUrl + "auth/app/phone-login", param)
  }
  static logout() {
    return http.post(baseUrl + "auth/tourist/logout")
  }
  static getCode(param) {
    return http.post("/zp-message/v1/message/sms/send-sms-code", param)
  }
  static getUserInfo() {
    return http.post("/zp-mobile/v1/user-info/user-info")
  }
}
export default AuthApi;