<template>
  <div class="page">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="header-title">管理后台登录</span>
      </div>
      <table style="margin: 0 auto; border-spacing: 22px">
        <tr>
          <td>手机号</td>
          <td>
            <el-input
              v-model="phone"
              maxlength="11"
              placeholder="请输入手机号"
            ></el-input>
          </td>
        </tr>
        <tr>
          <td>验证码</td>
          <td>
            <div style="position: relative">
              <el-input
                v-model="code"
                maxlength="4"
                placeholder="请输入验证码"
                @keydown.enter.native="doLogin"
              ></el-input>
              <el-button
                type="primary"
                style="width: 80px; position: absolute; right: 0"
                @click="getCode"
              >
                {{ countdown ? countdown + "s" : "获取" }}
              </el-button>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <el-button
              type="primary"
              style="width: 320px; margin-top: 15px"
              @click="doLogin"
              >登录</el-button
            >
          </td>
        </tr>
      </table>
      <div style="margin: 0 auto; text-align: center; color: #666666">
        该登录手机号与小程序端注册时填写的手机号一致
      </div>
    </el-card>
  </div>
</template>

<script>
import AuthApi from "../request/AuthApi";
export default {
  data() {
    return {
      phone: null,
      code: null,
      countdown: 0,
    };
  },
  methods: {
    async getCode() {
      if (!this.phoneValidator()) return;
      if (this.countdown > 0) return;
      const result = await AuthApi.getCode({
        countryCode: "86",
        phone: this.phone,
        type: "LOGIN",
      });
      if (!result.success) {
        this.$message(result.errMsg);
      } else {
        this.countdown = 60;
        this.onCountdown();
      }
    },
    phoneValidator() {
      if (this.phone == "") {
        this.$message("请输入手机号码");
        return false;
      }
      if (!/^1[3-9]\d{9}$/.test(this.phone)) {
        this.$message("请输入正确的手机号码");
        return false;
      }
      return true;
    },
    codeValidator() {
      if (this.code == "") {
        this.$message("请输入短信验证码");
        return false;
      }
      if (!/^\d{4}$/.test(this.code)) {
        this.$message("验证码格式错误");
        return false;
      }
      return true;
    },
    onCountdown() {
      setTimeout(
        function () {
          if (--this.countdown) {
            this.onCountdown();
          }
        }.bind(this),
        1000
      );
    },
    async doLogin() {
      if (!this.phone) {
        this.$message("请输入手机号");
      }
      if (!this.code) {
        this.$message("请输入验证码");
      }
      const result = await AuthApi.login({
        countryCode: "86",
        phone: this.phone,
        code: this.code,
      });
      if (result.success) {
        this.$store.commit("setToken", result.data.token);
        this.$router.replace({
          path: "/",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 100vw;
  height: 100vh;
  background-color: rgb(64, 158, 255);
  display: flex;
  justify-content: center;
}
.box-card {
  margin-top: 200px;
  // margin-left: auto;
  // margin-right: 200px;
  width: 500px;
  height: 350px;
}
.header-title {
  display: flex;
  justify-content: center;
  font-size: 26px;
  font-weight: medium;
  color: dodgerblue;
}
</style>
